import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { Padding, p, py, pl, pt } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

import colors from '@nib-components/colors';
import { Stack } from '@nib/layout';
import { TickSystemIcon } from '@nib/icons';
import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import { colorTrueGreen } from '@nib-components/theme';

const Wrapper = styled.div`
  ${py(0)};
  display: grid;

  ${breakpoint('lg')`
    grid-template-columns: 1fr 1fr;
  `};
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${pt(6)};

  ${breakpoint('lg')`
    ${pt(0)};
    ${pl(8)};
  `};
`;

export const FeatureBlock = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  ${p(4)};
  ${(props) =>
    props.isActive &&
    css`
      cursor: pointer;
    `}

  border-left: 2px solid ${(props) => (props.isActive ? colorTrueGreen : colors.sneezy)};
  color: ${(props) => (props.isActive ? colors.doc : colors.dopey)};
`;

const CustomTick = styled(TickSystemIcon)`
  ${p(2)};
  border: 2px solid transparent;

  color: ${colorTrueGreen};
  ${(props) =>
    props.isActive &&
    css`
      border-color: ${colorTrueGreen};
      border-radius: 50%;
    `}

  transform: translateY(-0.5rem);
`;

const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeOutAnimation = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const ImageWrapper = styled.div`
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  position: relative;

  ${breakpoint('md')`
    min-height: 300px;
  `};

  ${breakpoint('lg')`
    height: 100%;
    width: auto;
  `};
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  opacity: 0;

  ${breakpoint('lg')`
    height: 100%;
    width: auto;
  `};

  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
    `}
`;

const AnimatedImage = styled(Image)`
  animation-name: ${fadeOutAnimation};
  animation-duration: 1s;
  animation-iteration-count: 1;

  ${(props) =>
    props.isActive &&
    css`
      animation-name: ${fadeInAnimation};
      animation-duration: 1s;
      animation-iteration-count: 1;
    `}
`;

const VerticalFeaturePanel = ({ description, features, useAnimation }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeIndex < features.length - 1) {
        const newIndex = activeIndex + 1;
        setActiveIndex(newIndex);
      } else {
        setActiveIndex(0);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <Wrapper data-testid="vertical-feature-panel">
      <ImageWrapper>
        {features &&
          features.map((feature, index) => {
            return useAnimation ? (
              <AnimatedImage key={index} src={feature.image} isActive={index === activeIndex} />
            ) : (
              <Image key={index} src={feature.image} isActive={index === activeIndex} />
            );
          })}
      </ImageWrapper>
      <ContentWrapper>
        {description && description.title && (
          <Padding bottom="6">
            <Stack space={6}>
              <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="trueGreen">
                {description.title}
              </Heading>
              {description.subTitle && <Copy>{description.subTitle}</Copy>}
            </Stack>
          </Padding>
        )}
        {features &&
          features.map((feature, index) => {
            return (
              <FeatureBlock
                key={index}
                isActive={useAnimation && index === activeIndex}
                onClick={() => setActiveIndex(index)}
                data-testid={`feature-block-${index}`}
              >
                <CustomTick isActive={useAnimation && index === activeIndex} />
                <Padding left="4">
                  <Stack space={3}>
                    <Heading color="trueGreen" size={3}>
                      {feature.heading}
                    </Heading>
                    <Copy color="darkest">{feature.content()}</Copy>
                  </Stack>
                </Padding>
              </FeatureBlock>
            );
          })}
        {description && description.footer && (
          <Padding top="6">
            <Copy>{description.footer}</Copy>
          </Padding>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

VerticalFeaturePanel.propTypes = {
  description: PropTypes.object,
  features: PropTypes.array,
  useAnimation: PropTypes.bool,
};

VerticalFeaturePanel.defaultProps = {
  useAnimation: true,
};

VerticalFeaturePanel.displayName = 'VerticalFeaturePanel';

export default VerticalFeaturePanel;
